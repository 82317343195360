<template>
  <div>
    <div v-for="(rating, index) in ratings" :key="index">
      <ratings-viewer
        v-if="rating.status === 'completed'"
        :key="index"
        :rating="rating"
        :label="rating._id"
        :disabled="true"
      />
      <ratings-viewer-error
        v-else-if="rating.status === 'error'"
        :key="index + 1"
        :rating="rating"
        :label="rating._id"
        :disabled="true"
      />
      <ratings-viewer-skipped
        v-else-if="rating.status === 'skipped'"
        :key="index + 3"
        :rating="rating"
        :label="rating._id"
        :disabled="true"
      />
      <ratings-skeleton
        v-else-if="rating.status === 'in-progress'"
        :key="index + 4"
        :rating="rating"
        :label="rating._id"
        :disabled="true"
      />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import RatingsViewer from "./RatingsViewer.vue";
import RatingsViewerError from "./RatingsViewerError.vue";
import RatingsViewerSkipped from "./RatingsViewerSkipped.vue";
import RatingsSkeleton from "./RatingSkeleton.vue";
export default Vue.extend({
  components: {
    RatingsViewerSkipped,
    RatingsViewerError,
    RatingsViewer,
    RatingsSkeleton
  },
  name: "ratings-activity",
  props: {
    ratings: {
      type: Array,
      required: true
    }
  }
});
</script>
