
import {
  dwellingTypeOptions,
  paymentPlanOptions,
  propertyUsageOptions,
  structureTypeOptions
} from "@/forms/shared/quote.dwelling_fire";
import {
  tenantDwellingOptions,
  tenantStructureTypeOptions
} from "@/forms/shared/quote.tenant";
import { getOrigin } from "@/helpers";
import {
  billingPlanOptions,
  occupancyOptions,
  allRoofMaterialOptions
} from "@/helpers/selectOptions";
import getQuestions from "@/helpers/underwritingQualifications";
import { formatQuoteUpdateText } from "@/helpers/quoteActivitiesFormatter";
import { quoteMapActions } from "@/store/modules/quote";
import { IRating } from "@/store/modules/quote/types";
import { userMapActions } from "@/store/modules/user";
import { format } from "date-fns";
import { cloneDeep, get } from "lodash";
import { capitalize, isEmpty } from "lodash";
import Vue from "vue";
type options = "0" | "1" | "2" | "3" | "4";
type structureType = "M" | "B" | "A" | "F";
interface IQuoteActivityMixinData {
  content: string;
  icon: string;
  type: string;
  link: Record<string, any>;
  color: string;
  size: "large";
  linkColor: string;
  linkColorDark: string;
  displayRatingActivity: boolean;
  ratingViewExpanded: boolean;
  ratePolicyType: string;
  ratings: IRating[];
  showTerritoryData: boolean;
  toggleTerritoryData: boolean;
}
const QuoteActivitiesMixin: any = Vue.extend({
  props: {
    activity: {
      type: Object,
      required: true
    },
    quote: {
      type: Object,
      required: false
    }
  },
  data: function(): IQuoteActivityMixinData {
    return {
      content: "",
      icon: "",
      type: "",
      link: {},
      color: "",
      size: "large",
      linkColor: "#409EFF",
      linkColorDark: "#034b9d",
      displayRatingActivity: false,
      ratingViewExpanded: false,
      ratePolicyType: "",
      ratings: [],
      showTerritoryData: false,
      toggleTerritoryData: false
    };
  },
  mounted() {
    this.formatRatingActivity();
  },
  methods: {
    ...userMapActions(["getUser"]),
    ...quoteMapActions(["getQuote"]),
    formatDate(date_str: string) {
      return format(new Date(date_str), "MMMM d, y");
    },
    expandRatingView() {
      this.ratingViewExpanded = !this.ratingViewExpanded;
    },
    handleToggleTerritoryData() {
      this.toggleTerritoryData = !this.toggleTerritoryData;
    },

    async extractQuoteData() {
      try {
        const quote = await this.getQuote(this.activity.data.quoteId);
        this.ratePolicyType = quote.policyType;
        return quote;
      } catch (error) {
        this.$appNotifyError(error.message);
        this.$bugSnagClient.notify(error);
      }
    },
    async formatRatingActivity() {
      if (this.activity.name == "RATE_QUOTE_SUCCESS") {
        await this.extractQuoteData();
        this.displayRatingActivity = true;
        this.ratings = this.activity.data.ratings;
      }
    },
    baseLink(userId: string) {
      return `${getOrigin()}/admin/users/${userId}/edit`;
    },
    async generateReadableUpdateQuote(userLink: string = "{userName}") {
      let contentHtml = ``;
      let endorsementsArray = [];
      let companiesArray = [];
      const questions = getQuestions(
        get(this.quote, "ratingSelectedData.companyNumber"),
        get(this.quote, "policyType"),
        get(this.quote, "isStatewide")
      );
      for (let update of this.updates) {
        if (
          update.field.includes("underwritingResponses") &&
          update.field.includes("answer") &&
          !isEmpty(questions)
        ) {
          let key = update.field
            .replace(".answer", "")
            .replace("underwritingResponses.", "");
          //find question
          let question = questions
            .map((q: any) => q.children)
            .flat()
            .map((q: any) => q.properties)
            .find((q: any) => q.key === key);
          const answer = this.updates.find((answer: { field: string[] }) =>
            answer.field.includes(key)
          );
          contentHtml =
            contentHtml +
            `<p>${userLink} answered an underwriting question: ${question?.preamble}</p>
            <p><b>Answer: </b>${answer.update}</p>`;
        } else if (
          update.field.includes("creditInformation") &&
          update.field.includes("scoreVerbiage")
        ) {
          const answer = this.updates.find((answer: any) =>
            answer.field.includes("scoreIndicatorFlag")
          );
          contentHtml =
            contentHtml +
            `<p>${userLink} run applicant's credit.</p>
              <p>Message: ${update.update}</p>
              <p>Flagged: ${answer.update}</p>`;
        } else if (update.field.includes("lossReport")) {
          contentHtml =
            contentHtml +
            `<p>${userLink} run applicant's ${update.field}.</p>
            <p>Update: ${update.update}</p>
            `;
        } else if (
          update.field.includes("endorsements") &&
          update.field.includes("formNumber")
        ) {
          endorsementsArray.push(update.update);
        } else if (update.field.includes("companyNumbers")) {
          companiesArray.push(
            update.update == "99"
              ? "20 Statewide"
              : update.update == "20"
              ? "20 Seacoast"
              : update.update
          );
        } else if (
          update.field.includes("mortgages") &&
          update.field.includes("mortgageName")
        ) {
          contentHtml =
            contentHtml +
            `<p>${userLink} added a mortgage: ${
              update.update ? update.update : "N/A"
            }`;
        } else if (
          update.field.includes("mortgages") &&
          update.field.includes("loanNumber")
        ) {
          contentHtml =
            contentHtml +
            formatQuoteUpdateText({
              userLink,
              linkColor: this.linkColor,
              activity: this.activity,
              field: "loan number",
              requiredData: update.update ? update.update : "N/A"
            });
        } else if (update.field.includes("paymentPlan")) {
          contentHtml =
            contentHtml +
            formatQuoteUpdateText({
              userLink,
              linkColor: this.linkColor,
              activity: this.activity,
              field: "payment plan",
              requiredData: this.getPaymentPlan(update.update)
            });
        } else if (update.field.includes("billing")) {
          contentHtml =
            contentHtml +
            formatQuoteUpdateText({
              userLink,
              linkColor: this.linkColor,
              activity: this.activity,
              field: "billing plan",
              requiredData: this.getBillingPlan(update.update)
            });
        } else if (update.field.includes("useAge")) {
          contentHtml =
            contentHtml +
            formatQuoteUpdateText({
              userLink,
              linkColor: this.linkColor,
              activity: this.activity,
              field: "property usage",
              requiredData: this.getPropertyUsage(update.update)
            });
        } else if (update.field.includes("dwellType")) {
          contentHtml =
            contentHtml +
            formatQuoteUpdateText({
              userLink,
              linkColor: this.linkColor,
              activity: this.activity,
              field: "dwelling type",
              requiredData: this.getDwellingType(update.update)
            });
        } else if (update.field.includes("structureType")) {
          contentHtml =
            contentHtml +
            formatQuoteUpdateText({
              userLink,
              linkColor: this.linkColor,
              activity: this.activity,
              field: "building structure type",
              requiredData: this.getStructureType(update.update)
            });
        } else if (update.field.includes("occupancy")) {
          contentHtml =
            contentHtml +
            formatQuoteUpdateText({
              userLink,
              linkColor: this.linkColor,
              activity: this.activity,
              field: "payment plan",
              requiredData: this.getOccupancy(update.update)
            });
        } else if (update.field.includes("swimmingPoolOnPremises")) {
          contentHtml =
            contentHtml +
            formatQuoteUpdateText({
              userLink,
              linkColor: this.linkColor,
              activity: this.activity,
              field: '"Is there a swimming pool on the premises? "',
              requiredData: update.update == 0 ? "No" : "Yes"
            });
        } else if (update.field.includes("propertyUpdates")) {
          contentHtml =
            contentHtml +
            formatQuoteUpdateText({
              userLink,
              linkColor: this.linkColor,
              activity: this.activity,
              field: '"Have there been any updates to the property? "',
              requiredData: update.update == 0 ? "No" : "Yes"
            });
        } else if (update.field.includes("roofMaterial")) {
          contentHtml =
            contentHtml +
            formatQuoteUpdateText({
              userLink,
              linkColor: this.linkColor,
              activity: this.activity,
              field: "roof material",
              requiredData: this.getRoofMaterial(update.update)
            });
        } else if (
          update.field.includes("Date") ||
          update.field.includes("date")
        ) {
          let formattedDate = this.formatDate(update.update);
          contentHtml =
            contentHtml +
            formatQuoteUpdateText({
              userLink,
              linkColor: this.linkColor,
              activity: this.activity,
              field: update.field,
              requiredData: formattedDate
            });
        } else if (update.field.includes("createdBy")) {
          const user = await this.getUser(update.update);
          contentHtml =
            contentHtml +
            formatQuoteUpdateText({
              userLink,
              linkColor: this.linkColor,
              activity: this.activity,
              field: "quote's agent",
              requiredData: user.fullName
            });
        } else if (update.field.includes("territory")) {
          this.showTerritoryData = true;
          contentHtml = contentHtml + `<p>${userLink} selected a territory</p>`;
        } else if (
          update.field.includes("underwritingResponses") ||
          update.field.includes("creditInformation") ||
          update.field.includes("endorsements") ||
          update.field.includes("mortgages") ||
          update.field.includes("originalCreator")
        ) {
          continue;
        } else {
          contentHtml = (this as any).generateRegularActivityString(
            contentHtml,
            update,
            userLink
          );
        }
      }
      if (endorsementsArray.length) {
        contentHtml =
          contentHtml +
          formatQuoteUpdateText({
            userLink,
            linkColor: this.linkColor,
            activity: this.activity,
            field: "endorsement",
            requiredData: endorsementsArray,
            requireFormatting: true
          });
      }
      if (companiesArray.length) {
        contentHtml =
          contentHtml +
          formatQuoteUpdateText({
            userLink,
            linkColor: this.linkColor,
            activity: this.activity,
            field: "companies",
            requiredData: companiesArray,
            requireFormatting: true
          });
      }
      (this as any).setReadableUpdateProps(contentHtml);
    },
    getPaymentPlan(paymentCode: options) {
      let options = paymentPlanOptions.find(
        option => option.value === paymentCode
      );
      return options?.label || "";
    },
    getBillingPlan(billingCode: options) {
      let options = billingPlanOptions.find(
        option => option.value === billingCode
      );
      return options?.label || "";
    },
    getPropertyUsage(propertyUsage: options) {
      let options = propertyUsageOptions.find(
        option => option.value === propertyUsage
      );
      return options?.label || "";
    },
    getDwellingType(dwellingType: options) {
      const allOptions = [...tenantDwellingOptions, ...dwellingTypeOptions];
      let options = allOptions.find(option => option.value === dwellingType);
      return options?.label || "";
    },
    getStructureType(structureType: structureType) {
      const allOptions = [
        ...tenantStructureTypeOptions,
        ...structureTypeOptions
      ];
      let options = allOptions.find(option => option.value === structureType);
      return options?.label || "";
    },
    getOccupancy(occupancy: "O" | "T") {
      let options = occupancyOptions.find(option => option.value === occupancy);
      return options?.label || "";
    },
    getRoofMaterial(roofMaterial: "6" | "7" | "8" | "9") {
      let options = allRoofMaterialOptions.find(
        option => option.value === roofMaterial
      );
      return options?.label || "";
    }
  },
  computed: {
    hasValidTerritoryData(): boolean {
      return (
        this.data &&
        Object.keys(this.data).length &&
        this.data?.territory &&
        Object.keys(this.data?.territory).length
      );
    },
    sortedData() {
      const ratings: IRating[] =
        this.ratings && this.ratings.length ? cloneDeep(this.ratings) : [];
      const sortedRatings = ratings.sort((a: IRating, b: IRating) => {
        const aCompanyName = get(a.companyData, "companyName", "");
        const bCompanyName = get(b.companyData, "companyName", "");
        return aCompanyName < bCompanyName
          ? -1
          : aCompanyName > bCompanyName
          ? 1
          : 0;
      });

      return sortedRatings;
    },
    formattedActivityText(): any {
      const linksArray = Object.keys(this.link);
      let content = this.content;
      if (linksArray.length) {
        linksArray.map((linkKey: any) => {
          const link = (this.link as any)[linkKey];
          if (link) {
            const { name, href } = link;
            let linkNode;
            if (href) {
              linkNode = `<a style="color: ${this.linkColor}" href="${href ||
                "#"}"> ${name} </a>`;
            } else if (name) {
              linkNode = `<span> ${name} </span>`;
            } else {
              linkNode = "";
            }
            const reg = new RegExp(`{${linkKey}}`, "g");
            content = content.replace(reg, linkNode);
          }
        });
      }
      return content;
    },
    data(): any {
      return this.activity.data;
    },
    updates(): any {
      return get(this.data, "updates");
    },
    deletes(): any {
      return get(this.data, "deletes", []);
    },
    failureReason(): any {
      return get(this.data, "reason");
    },
    accountTypeFormatted(): any {
      let nameToDisplay = "";
      if (!this.activity.name.includes("FAILURE")) {
        if (this.$getCurrentUser._id === this.activity.userId) {
          nameToDisplay = "You";
        } else {
          const fullName = get(this.activity.userData, "fullName", "") || "";
          const firstName = get(this.activity.userData, "firstName", "") || "";
          const lastName = get(this.activity.userData, "lastName", "") || "";
          nameToDisplay = `${firstName} ${lastName}`;
          if (fullName) {
            nameToDisplay = fullName;
          }
        }
      }
      let link = {
        name: capitalize(nameToDisplay),
        href: this.baseLink(this.activity.userId)
      };
      return { userName: link };
    }
  }
});
export default QuoteActivitiesMixin;
